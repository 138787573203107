//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {flightPlan} from '@/apis/tool';
export default {
	data: ()=> ({
		dep: '',
		des: '',
		isDirect: false,
		isCargoPlane: false,
		airline: '',
		activeIcon: 'https://img01.yzcdn.cn/vant/user-active.png',
      	inactiveIcon: 'https://img01.yzcdn.cn/vant/user-inactive.png',
		dataSource: [],
		inRNBox: false,
		navigationBarBackgroundColor: '#437AFF',
	}),
	created() {
		this.inRNBox = window.ReactNativeWebView || !!this.$route.query.inRNBox || false
		if(this.inRNBox) {
			this.navigationBarBackgroundColor = '#' + (this.$route.query.navigationBarBackgroundColor || '154B79')
		}
	},
	mounted() {
		this.des = 'FRA'
		this.dep = 'CAN'
		this._getPlans({
			departure: this.dep,
			destination: this.des,
			isDirect: '',
			isCargoPlane:  '',
			airline:  '',
			pageNum: 1,
			pageSize: 30,
		})
	},
	methods: {
		formatter(value) {
			  // 过滤输入的数字
			let s = value.replace(/[^\w\.\/]/ig,'')
				s = s.toUpperCase().substring(0,5)
      		return s
    	},
		submit() {
			let airline = this.airline,
				departure = this.dep,
				destination = this.des
			if(airline) {
				airline = airline.toLocaleUpperCase()
				if(!/^[A-Z0-9]{2}$/.test(airline)) {
					this.$toast('航司二字码有误，请仔细核对')
					return
				}
			}
			if(!departure || !destination) {
				this.$toast('始发港、目的港不能为空')
				return
			}
			let reg = /^[A-Z]{3}$/
			if(!reg.test(destination) || !reg.test(departure)) {
				this.$toast('始发港、目的港有误，请仔细核对')
				return
			}
			this._getPlans({
				departure,
				destination,
				airline,
				isDirect: this.isDirect ? 1 : '',
				isCargoPlane: this.isCargoPlane ? 1 : '',
				pageSize: 50,
				pageNum: 1,
			})
		},
		async _getPlans(params) {
			let {data} = await flightPlan(params)
			this.dataSource = data || []
		}
	}
}
